<template>
  <div>
    <div class="text-center mt-8">
      <v-btn
        color="primary"
        rounded
        @click="add_analysis_dialog()"
        fab
        top
        right
        absolute
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $t("title") }}</th>
          <th class="text-center">{{ $t("edit") }}</th>
          <th class="text-center">{{ $t("remove") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in analysis.filter(
            (x) => x.Type == 0 && gs.check_field(x)
          )"
          :key="index"
        >
          <td>
            <div v-if="gs.isJson(item.Title)">
              <p
                class="mb-1"
                v-for="(k, i) in Object.keys(JSON.parse(item.Title))"
                :key="i"
              >
                <b style="font-weight: bold">{{ `${k.toUpperCase()}: ` }}</b
                >{{ `${JSON.parse(item.Title)[k]}` }}
              </p>
            </div>
            <span v-else>{{ item.Title }}</span>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="edit_analysis_dialog(item)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(item)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="dialog" v-model="dialog">
      <ManageAnalysis
        v-if="dialog"
        @close="dialog = false"
        @reset="reset_dialog"
        :CompanyId="company_prop.CompanyId"
        :BriefingId="BriefingId"
        :IndexOptions="index_options"
        :Analysis="analysis_obj"
        :AnalysisType="0"
      />
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Title"
        @close="delete_dialog = false"
        @delete="remove_analysis"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageAnalysis from "./Dialogs/ManageAnalysis.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "ManageCompanyAnalysis",

  components: {
    ManageAnalysis,
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    analysis: [],
    analysis_obj: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    index_options() {
      var i;
      var return_list = [];
      for (i = 1; i <= this.analysis.filter((x) => x.Type == 0).length; i++) {
        return_list.push(i);
      }
      if (!this.analysis_obj) {
        return_list.push(this.analysis.filter((x) => x.Type == 0).length + 1);
      }
      return return_list;
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.refresh_analysis_type();
  },

  methods: {
    refresh_analysis_type() {
      if (this.company_prop != null) {
        this.analysis = this.company_prop.CompanyAnalysis.filter(
          (x) => x.Type == 0
        );
      }
    },
    open_delete_dialog: function (analysis) {
      this.delete_obj = analysis;
      this.delete_dialog = true;
    },
    remove_analysis: async function (obj) {
      this.loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest(`companyanalysis/delete/${obj.Id}`)
        .then((result) => {
          var objs = JSON.parse(result.message);
          this.$emit("reset", objs);
          this.refresh_analysis_type();
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.loading = false;
    },
    add_analysis_dialog: function () {
      this.analysis_obj = null;
      this.dialog = true;
    },
    edit_analysis_dialog: function (obj) {
      this.analysis_obj = obj;
      this.dialog = true;
    },
    reset_dialog(objs) {
      this.$emit("reset", objs);
      this.refresh_analysis_type();
      this.dialog = false;
    },
  },
};
</script>
